<template>
    <div class="media">
        <div class="video">
            <VideoInputPlayer
                v-for="video in videos"
                :key="video._id"
                :video="video.url"
                :text="video.title"
                :action="() => deleteMDMedia(video._id, video.isLocal)"
                :link="video.url"
                :upload="`${labels.video}:`"
                :is-multiple="false"
            />

            <VideoInputPlayer
                :button-label="labels.upload_video"
                :is-multiple="false"
            >
                <Button
                    class="btn-slateblue"
                    :label="labels.select_video"
                    padding="11px 20px"
                    icon="custom-upload"
                    icon-position="right"
                    @click="showPopup = true"
                />
            </VideoInputPlayer>
        </div>
        <MediaPostList
            class="cards"
            :label="labels.upload_audio"
            :cards="audios"
        >
            <div class="button">
                <Button
                    class="btn-slateblue"
                    :label="labels.select_audio"
                    padding="11px 20px"
                    icon="custom-upload"
                    icon-position="right"
                    @click="showPopup = true"
                >
                </Button>        
            </div>
        </MediaPostList>

        <MediaLibrary
            v-if="showPopup"
            modal-id="1"
            type="audio"
            custom-class="gray-header"
            v-bind:show-popup="showPopup"
            @get-media="getMedia"
            @close="onClose"
        />
    </div>
</template>

<script>
import VideoInputPlayer from '@/components/Media/VideoInputPlayer';
import MediaPostList from '@/components/Media/MediaPostList';
import MediaLibrary from '@/components/MediaGallery/MediaLibrary';
import Button from '@/components/Buttons/Button';

import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';

export default {
    name: "Media",
    components: {
        VideoInputPlayer,
        MediaPostList,
        MediaLibrary,
        Button,
    },
    data() {
        return {
            showPopup: false
        };
    },
    computed: {
        ...mapState( '_module', {
            stateSingleData: 'stateSingleData',
            singleData: 'singleData',
            filesToSave: 'filesToSave'
        }),
        ...mapState( "helper", {
			user_id: 'user_id',
			admin_roles: 'admin_roles'
		}),

        ...mapGetters('helper', ['labels']),


        isAuthor() {
			let flag = false;
            if(this.admin_roles && Array.isArray(this.admin_roles)){
                if (this.admin_roles.includes('author')) {
                    this.singleData.authors?.forEach(author => {
                        if (author._id === this.user_id) {
                            flag = true;
                        }
                    })
                }
            }
			
            return flag;
        },
        videos() {
            let result = [];

            if (this.stateSingleData.media) {
                this.stateSingleData.media.forEach(media => {
                    if (media.mediaType === 'video') {
                        result.push({
                            title: media.title,
                            url: media.media,
                            isLocal: false,
                            _id: media._id
                        });
                    }
                });
            }

            this.filesToSave.forEach(file => {
                if (file.mediaType === 'video') {
                    result.push({
                        title: file.title,
                        url: file.media,
                        isLocal: true,
                        _id: file.id
                    });
                }
            });

            return result;
        },
        audios() {
            let result = [];

            if (this.stateSingleData.media) {
                this.stateSingleData.media.forEach(media => {
                    if (media.mediaType === 'audio') {
                        result.push({
                            img: 'https://assets.medpagetoday.net/media/images/94xxx/94222.jpg',
                            title: media.title,
                            name: media.title,
                            label: `${this.labels.title}:`,
                            link: this.generateLink(media.media),
                            action: () => this.deleteMDMedia(media._id)
                        })
                    }
                })
            }

            if (this.filesToSave.length) {
                this.filesToSave.forEach(media => {
                    if (media.mediaType === 'audio') {
                        result.push({
                            img: 'https://assets.medpagetoday.net/media/images/94xxx/94222.jpg',
                            title: media.title,
                            name: media.title,
                            label: `${this.labels.title}:`,
                            link: this.generateLink(media.media),
                            action: () => this.deleteMDMedia(media.id, true)
                        })
                    }
                })
            }

            return result;
        }
    },
    methods: {
        ...mapActions( '_module', {
            addMedia: 'addMedia',
            deleteMedia: 'deleteMedia',
        } ),
        ...mapActions( 'notification', ['addNotification'] ),
        ...mapMutations( '_module', ['updateStateSingleData', 'pushLocalMedia', 'resetLocalMedia', 'spliceLocalMedia'] ),
        generateFileTitle(fileName) {
            return fileName.replace(/\.[^/.]+$/, '');
        },
        saveSelectedFiles() {
            let mediaToLink = [];

            this.filesToSave.forEach( (fileToSave) => {
                mediaToLink.push(fileToSave.id);
            });

            this.addMedia(
                {
                    id: this.stateSingleData._id,
                    media: mediaToLink
                }
            ).then(res => {
                if (res.success) {
                    this.addNotification( { variant: 'success', msg: ['saved'], labels: this.labels });
                    this.resetLocalMedia();
                }

                if (res.error) this.addNotification( { variant: 'danger', msg: res.error, labels: this.labels } );
            });
        },
        deleteMDMedia (media, isLocal) {
            console.log('Delete media:', media);
            // console.log('Is local media:', isLocal);

            if (isLocal) {
                const indexOfFile = this.filesToSave.findIndex(file => {
                    return file.id === media;
                });

                if (indexOfFile !== -1) {
                    this.spliceLocalMedia(indexOfFile);
                }

                //this.syncStore();
            } else {
                this.deleteMedia({
                    id: this.stateSingleData._id,
                    media: media
                }).then(res => {
                    if (res.success) {
                        this.addNotification( { variant: 'success', msg: ['deleted'], labels: this.labels });
                    }

                    if (res.error) this.addNotification( { variant: 'danger', msg: res.error, labels: this.labels } );
                });
            }
        },
        generateLink(link) {
            return `//${window.location.host}${link}`
        },
        getMedia(value) {
            const indexOfFile = this.filesToSave.findIndex(file => {
                return file.id === value._id;
            });

            const indexOfFileInState = this.stateSingleData.media.findIndex(file => {
                return file._id === value._id;
            });

            if (indexOfFile !== -1 || indexOfFileInState !== -1) {
                console.log('File already has been added!');
                return;
            }

            this.pushLocalMedia({
                id: value._id,
                title: value.title,
                media: value.media,
                mediaType: value.mediaType
            });

            //this.syncStore();
        },
        syncStore() {
            this.updateStateSingleData( {
                ...this.stateSingleData,
                ...{
                    ['media']: [
                        ...this.audios,
                        ...this.videos
                    ]
                }
            } );
        },
        onClose(status) {
            this.showPopup = status;
        }
    },
};
</script>

<style lang="scss" scoped>
.video {
    width: 75%;
}
.cards {
    width: 25%;
}

.button {
    display: flex;
    justify-content: center;
    padding: 25px;
}

// ::v-deep .base-card {
//     display: flex;
//     justify-content: center;
// }

@media only screen and (max-width: 1000px){
    .video {
        width: 60%;
    }
    .cards {
        width: 40%;
    }
}
@media only screen and (max-width: 700px){
    .media {
        display: block;
        .video {
            width: 92%;
        }
        .cards {
            width: 92%;
        }
    }
}
</style>
